body {
	--project-rows: 3;
	--project-width: 480px;
}

#Intro {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

#Intro .content-row {
	/* position: absolute; */
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
}

@keyframes typewriter-blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.typewriter-symbol {
	font-weight: lighter;

	color: var(--highlight-color);
}

.typewriter-symbol[data-typing="false"] {
	animation: typewriter-blink 1s linear infinite;
}

#Intro h3 {
	text-align: center;
}

#Intro div h2 {
	text-align: center;
}

@keyframes blink {
	0% {
		opacity: 0;
	}

	50% {
		opacity: .5;
	}

	100% {
		opacity: 1;
	}

}

section>h2 {
	margin-bottom: 90px;
}

#Intro button {
	--size: 60px;
	position: absolute;
	top: 90%;
	transform: translateY(-50%);
	/* padding: 6px; */
	border-radius: 50%;
	font-size: 30px;
	width: var(--size);
	height: var(--size);
}

#project-search {
	width: 200px;
	height: 30px;
}

.projects-grid {
	display: inline-grid;
	grid-row-gap: 30px;
	grid-column-gap: 30px;
	grid-template-columns: repeat(var(--project-rows), var(--project-width));
	grid-template-rows: inherit;
	justify-content: space-between;
}

.projects-grid-item {
	display: flex;
	flex-direction: column;
	height: fit-content;
	align-items: center;
	justify-content: flex-start;
	transition: .3s ease;
	position: relative;
}

.projects-grid-item-overlay {
	position: relative;
	width: 100%;
	border-radius: 20px;
	aspect-ratio: 1.6;
	overflow: hidden;
	display: flex;
	z-index: 2;

}

.projects-grid-item-overlay .item-overlay {
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: .3s ease;
	background-color: #262626c4;
	opacity: 0;
	align-items: center;
	box-sizing: border-box;
	padding: 45px 20px;
}

.projects-grid-item-overlay h3 {
	font-size: 22px;
	text-align: center;
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	font-weight: 400;
	/* padding: 0 20px; */

}

.projects-grid-item:hover div div {
	opacity: 1;
}

.projects-grid-item:hover .projects-tag-container,
.projects-grid-item:hover .projects-title-container {
	background-color: transparent;
}

.projects-grid-item div img {
	object-fit: cover;
	width: 100%;
	filter: blur(0px);
}

.projects-grid-item:hover div img {
	filter: blur(3px);
}

.projects-grid-item span {
	display: flex;
	width: 100%;
	height: 30%;
	gap: 20px;
	margin-top: 20px;
}

.projects-grid-item span button h2 {
	margin: 0;
	font-size: 18px;
}

.projects-grid-item span button {

	width: 100%;
	margin-top: 5px;

	/* max-width: 50%; */
}

.projects-tag-container,
.projects-title-container {
	position: absolute;
	display: flex;
	background-color: #2626269e;
	padding: 0 6px;
	transition: .3s ease;
	height: 45px;
}

.projects-tag-container {
	top: 0;
	left: 0;
	border-radius: 20px 0px 20px 0px;
}

.projects-title-container {
	bottom: 0;
	right: 0;
	border-radius: 20px 0px 20px 0px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.projects-title-container h2 {
	margin: 0 10px;
	font-size: 20px;
	font-weight: bold;
}

.projects-tag-icon {
	color: white;
	font-size: 25px;
	margin: 10px;
}


form {
	position: relative;
	display: flex;
	min-width: 300px;
	width: 80%;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	gap: 10px;
}

form div {
	display: flex;
	justify-content: space-between;
}

form label {
	position: relative;
	width: 100%;
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-size: 20px;
	gap: 10px;
	color: white;
	/* margin: 2px; */
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

form label input {
	height: 40px;
	width: 100%;
	border-radius: 10px;
	border: 0px;
	padding: 0 10px;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}


form label[aria-label="areaContainer"] {
	position: relative;
	margin: 0 5px;
}

form textarea {
	width: 100%;
	height: 200px;
	resize: none;
	border-radius: 10px;
	border: 0px;
	padding: 10px 10px;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

form span {
	display: flex;
	height: inherit;
	margin-top: 10px;
}

.contact-submit {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-left: 5px;
	margin-right: 5px;
}

.contact-submit button {
	width: 200px;

}

@media only screen and (max-width: 1600px) {
	section>h2 {
		margin-bottom: 30px;
	}

	#Intro button {
		--size: 40px
	}
}

@media only screen and (max-width: 800px) {
	form span {
		flex-direction: column;
	}


}

@media only screen and (max-width: 700px) {
	body {
		--project-width: 400px;
	}

	.projects-grid-item-overlay h3 {
		font-size: 16px;
	}
}

@media only screen and (max-width: 500px) {
	body {
		--project-width: 300px;
	}
}