@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
	overflow-x: hidden;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--level0);
	width: 100vw;
	height: 100vh;
	overflow: hidden;

	--level0: #101114;
	--highlight-color: rgb(123, 255, 145);
	--section-sep: #ffffff7e;
	--button: white;
	--button-hover: #00ff37;
	--scroll: white;
	--text0: white;
}

#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0;
	background-color: var(--level0);
}

#content {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: overlay;
}

#socials {
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0%;
	top: 50%;
	transform: translateY(-50%);
	background-color: #191a1d;
	border-radius: 0px 20px 20px 0px;
	z-index: 3;

}

#socials svg {
	display: flex;
	color: white;
	font-size: 30px;
	margin: 10px;
	transition: all ease-in-out 0.3s;
	cursor: pointer;
}

#socials svg:hover {
	color: var(--button-hover);
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: var(--scroll);
	border-radius: 10px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 100px;
	display: block;
	color: var(--text0);
	margin: 2px;
}

h2 {
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-size: 50px;
	display: block;
	color: var(--text0);
	margin: 2px;
}

h3 {
	font-family: 'Roboto', sans-serif;
	font-weight: lighter;
	font-size: 30px;
	display: block;
	color: var(--text0);
	margin: 2px;
}

p {
	font-family: 'Roboto', sans-serif;
	font-weight: lighter;
	font-size: 10px;
	display: block;
	color: rgb(173, 173, 173);
}

mark {
	background: transparent;
	color: var(--highlight-color)
}

button {
	appearance: none;
	width: fit-content;
	display: flex;
	justify-content: center;
	background: transparent;
	border: solid 3px var(--button);
	color: var(--button);
	border-radius: 10px;
	padding: 0;
	height: 40px;
	transition: all ease-in-out 0.3s;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

button:hover {
	border: solid 3px var(--button-hover);
	color: var(--button-hover);
}

button h2 {
	margin: 0;
	font-size: 18px;
}

section {
	padding: 5% 10%;
	box-sizing: border-box;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
}



.page {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-left: 52px;
	padding-right: 52px;
	padding-bottom: 100px;
}

.outer-section {
	width: 100%;
	min-height: fit-content;
}

.lazy-image-bg {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	filter: blur(10px);
}



/* Line between sections */
/* .outer-section::after {
	content: "";
	display: block;
	position: relative;
	height: 1px;
	width: 80%;
	margin: 0 10%;
	background: var(--section-sep);
} */


@media only screen and (max-width: 760px) {
	#socials {
		flex-direction: row;
		left: 50%;
		top: 100%;
		transform: translate(-50%, -100%);
		justify-content: center;
		border-radius: 20px 20px 0 0;
	}
}

@media only screen and (max-width: 720px) {
	h1 {

		font-size: 70px;

	}

	h2 {

		font-size: 40px;

	}

	h3 {

		font-size: 25px;

	}
}

@media only screen and (max-width: 500px) {
	h1 {

		font-size: 50px;

	}

	h2 {

		font-size: 30px;

	}

	h3 {

		font-size: 20px;

	}
}